<template>

<div class="filter">

	<div class="filter-icon"></div>

	<div class="filter-text" v-html="data.text" />

	<div class="filter-buttons">

		<app-button theme="purple" :text="data.buttons.yes" v-on:click="onButtonClick(1)" />
		<app-button theme="purple" :text="data.buttons.no" v-on:click="onButtonClick(0)" />

	</div>

</div>

</template>

<script>

export default {

	props: ['data'],

	methods: {

		onButtonClick: function(value) {

			this.$store.commit('answer', {
				name: 'filter',
				value: value
			})

			this.$emit('answered', value)

		}

	}

}

</script>

<style scoped>

.filter {
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 684px;
}

.filter-back {
	position: absolute;
	left: 10px;
	top: 0px;
	color: #fff;
	letter-spacing: 1px;
	text-transform: uppercase;
	font-size: 16px;
	cursor: pointer;
}

.filter-back:hover span {
	text-decoration: underline;
}

.filter-icon {
	width: 143px;
	height: 143px;
	background-size: 143px 143px;
	background-position: 50% 50%;
	background-image: url(~@/assets/questions.filter.svg);
	margin-bottom: 40px;
}

.is-mobile .filter-icon {
	width: 64px;
	background-size: 64px 64px;
	height: 64px;
	margin-bottom: 20px;
}

.filter-text {
	color: #fff;
	font-size: 24px;
	text-align: center;
	line-height: 29px;
	margin-bottom: 40px;
}

.is-mobile .filter-text {
	font-size: 18px;
	line-height: 24px;
	padding: 0px 10px;
	margin-bottom: 20px;
}

.filter-buttons {
	display: flex;
	flex-direction: row;
}

.is-mobile .filter-buttons {
	flex-direction: column;
	width: 100%;
	padding: 0px 10px;
}

.filter-buttons >>> .button {
	margin: 0px 10px;
}

.is-mobile .filter-buttons >>> .button {
	margin: 0px 0px 10px 0px;
	width: 100%;
}

</style>
